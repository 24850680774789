.file-upload-container {
    width: 250px;
    height: 250px;
    border: 4px dotted #adadad;
    padding: 16px;
    position: relative;
    border-radius: 24px;
}

.file-upload-container input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}


.file-upload-container .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.file-upload-container .content img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.file-upload-container .content p {
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    background-color: #1A73E8;
    padding: 6px 16px;
    color: white;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    left: 50%;
    width: max-content;
    margin-bottom: 8px;
    border-radius: 6px;
}
