.loader_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #00000020 !important;
    top: 0;
    margin-top: 0 !important;
    z-index: 999999;
}

#app.active-loader {
    overflow: hidden;
    height: 100vh;
}